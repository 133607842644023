import { Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from '../images/official-logo-full-name.svg';
import { FaEnvelope } from 'react-icons/fa';

const Header = () => {
	const [ isExpanded, toggleExpansion ] = useState(false);
	// const { site } = useStaticQuery(graphql`
	// 	query SiteTitleQuery {
	// 		site {
	// 			siteMetadata {
	// 				title
	// 			}
	// 		}
	// 	}
	// `);

	return (
		<header className="bg-white sticky top-0 mx-1 z-10 md:mx-6 lg:mx-8 xl:mx-0">
			<div className="flex flex-wrap items-center justify-between max-w-8xl p-2 mx-auto">
				{/* <Link to="/">
					<h1 className="flex items-center text-white no-underline">
						<svg
							className="w-8 h-8 mr-2 fill-current"
							height="54"
							viewBox="0 0 54 54"
							width="54"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
						</svg>
						<span className="text-xl font-bold tracking-tight">{site.siteMetadata.title}</span>
					</h1>
				</Link> */}

				<Link to="/">
					<img
						src={Logo}
						alt="Willer Technologies Logo"
						className="max-w-xxs md:max-w-md lg:max-w-xl xl:max-w-md"
					/>
				</Link>

				<button
					className="items-center block px-3 py-2 text-wtblue rounded xl:hidden"
					onClick={() => toggleExpansion(!isExpanded)}
				>
					<svg
						className="w-5 h-5 fill-current md:w-8 md:h-8 lg:w-10 lg:h-10"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<title>Menu</title>
						<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
					</svg>
				</button>

				<nav className={`${isExpanded ? `block` : `hidden`} xl:block xl:items-center w-full xl:w-auto`}>
					{[
						{
							route: `/services`,
							title: `Services`
						},
						{
							route: `/contact`,
							title: `Contact`
						},
						{
							route: `/about`,
							title: `About`
						},
						{
							route: `/portfolio`,
							title: `Portfolio`
						}
					].map((link) => (
						<Link
							className="block mt-4 text-wtblue text-lg font-medium no-underline text-center transition duration-500 ease-in-out hover:shadow-hover py-1 xl:inline-block md:mt-0 md:ml-6 md:text-2xl"
							key={link.title}
							to={link.route}
						>
							{link.title}
						</Link>
					))}
					<p className="block mt-4 text-wtblue text-lg font-medium no-underline text-center xl:inline-block md:mt-0 md:ml-6 md:text-2xl hover:text-wtbluehover">
						<FaEnvelope style={{ display: 'inline-block' }} />{' '}
						<a href="mailto:info@willertechnologies.com">info@willertechnologies.com</a>
					</p>
				</nav>
			</div>
		</header>
	);
};

export default Header;
