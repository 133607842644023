import React from 'react';
import { Link } from 'gatsby';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Footer = () => {
	return (
		<footer className="bg-gray-200 border-t border-gray-200 w-full p-3 pin-b text-center flex-row">
			<div>
				<ul className="flex flex-row justify-center py-3">
					<li className="text-md px-2 font-bold hover:text-wtblue md:text-lg md:px-8">
						<Link to="/services">Services</Link>
					</li>
					<li className="text-md px-2 font-bold hover:text-wtblue md:text-lg md:px-8">
						<Link to="/contact">Contact</Link>
					</li>
					<li className="text-md px-2 font-bold hover:text-wtblue md:text-lg md:px-8">
						<Link to="/about">About</Link>
					</li>
					<li className="text-md px-2 font-bold hover:text-wtblue md:text-lg md:px-8">
						<Link to="/portfolio">Portfolio</Link>
					</li>
				</ul>
			</div>
			<div>
				<ul className="flex flex-row justify-center py-3">
					<li className="text-3xl px-4 hover:text-wtblue md:text-4xl md:px-8">
						<OutboundLink href="https://www.facebook.com/WillerTechnologies" rel="noreferrer" target="_blank">
							<FaFacebookSquare />
						</OutboundLink>
					</li>
					<li className="text-3xl px-4 hover:text-wtblue md:text-4xl md:px-8">
						<OutboundLink href="https://www.instagram.com/willer_technologies/" rel="noreferrer" target="_blank">
							<FaInstagramSquare />
						</OutboundLink>
					</li>
					<li className="text-3xl px-4 hover:text-wtblue md:text-4xl md:px-8">
						<OutboundLink href="https://www.linkedin.com/company/willer-technologies/" rel="noreferrer" target="_blank">
							<FaLinkedin />
						</OutboundLink>
					</li>
				</ul>
			</div>
			<div>
				<ul className="py-3 md:flex md:flex-row justify-center">
					<li className="pb-3 text-md hover:text-wtblue md:text-lg md:px-8 md:py-0">
						<p className="block">
							<FaEnvelope style={{ display: 'inline-block' }} />{' '}
							<a href="mailto:info@willertechnologies.com">info@willertechnologies.com</a>
						</p>
					</li>
					<li className="pt-3 text-md hover:text-wtblue md:text-lg md:px-8 md:py-0">
						<p className="block">
							<FaPhoneAlt style={{ display: 'inline-block' }} />{' '}
							<a href="tel:+19204609757">(920) 460-9757</a>
						</p>
					</li>
				</ul>
			</div>
			<p className="text-xs pt-3 md:text-sm">
				&copy; {new Date().getFullYear()} Willer Technologies LLC All Rights Reserved.
			</p>
		</footer>
	);
};

export default Footer;
